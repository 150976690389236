import React from 'react';

const UnderDevelopmentPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 bg-[#e4e4e7]">
      <div className="text-center">
        <h1 className="text-xl sm:text-3xl md:text-5xl lg:text-7xl text-[#044653] font-bold text-center mb-6">Website Under Development</h1>

        <p className="text-center text-sm md:text-lg text-gray-700">
          We're currently working hard to bring you an amazing website. Please check back later!
        </p>
        <a href='https://www.linkedin.com/company/ascendicsolutions/' target='_blank'>
          <button className='bg-[#044653] text-white my-6 font-bold py-3 px-4 rounded-full inline-flex items-center transform transition-transform duration-300 hover:scale-110 mx-auto'>
            Visit us on LinkedIn
          </button>
        </a>
      </div>
    </div>
  );
};

export default UnderDevelopmentPage;
